import { call } from "redux-saga/effects";
import { list, one } from "./utils";
import { apiRoutes } from "./api-routes";

export function url(apiUrl) {
  // console.log(process.env.BACKEND_BASE_URL);
  if (process.env.BACKEND_BASE_URL) {
    return `${process.env.BACKEND_BASE_URL}${apiUrl}`;
  } else {
    // console.log(process.env.BACKEND_BASE_URL);
    return "https://phuket.samui.rest" + apiUrl;
  }
}

export function* getObject(publicId) {
  return yield call(
    one,
    "GET",
    url(`${apiRoutes.GET_PROPOSITION}/${publicId}`)
  );
}

export function* getTypes() {
  return yield call(list, "GET", url(apiRoutes.GET_TYPES));
}

export function* getBeaches() {
  return yield call(list, "GET", url(apiRoutes.GET_BEACHES));
}

export function* getOptions() {
  return yield call(list, "GET", url(apiRoutes.GET_OPTIONS));
}

export function* search(data) {
  return yield call(one, "POST", url(apiRoutes.SEARCH), data);
}

export function* postForm(data) {
  return yield call(one, "POST", url(apiRoutes.REQUEST), data);
}
